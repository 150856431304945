<template>
  <v-container>
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <h3>
      <span v-html="$t('processing_form_edit_step_two_zulage_control')" /> -
      <span v-html="$t('processing_form_edit_step_two_period')" />
      {{ $moment(entireProcessingFormObject.validFrom).format('DD.MM.YYYY') }} -
      {{ $moment(entireProcessingFormObject.validUntil).format('DD.MM.YYYY') }}
    </h3>
    <v-col cols="12"><h5 v-html="$t(generateCustomSubHeaderTextfield())"></h5></v-col>

    <v-btn class="m-2" id="back" @click="goBack" color="secondary"><span v-html="$t('processing_form_edit_step_two_back')" /></v-btn>
    <v-btn class="m-2" @click="cancel" color="secondary">
      <span v-html="$t('processing_form_edit_step_two_cancel')" />
    </v-btn>
    <v-btn class="m-2" @click="goForward" color="primary"><span v-html="$t('processing_form_edit_step_two_forward')" /></v-btn>
    <div>
      <!--<p v-if="!subsidyInvoiceId" v-html="subsidyMessage"></p>-->
      <iframe v-if="subsidyInvoiceId" src="" ref="pdfContainer" width="100%" height="800px">
        <span v-html="$t('processing_form_edit_step_two_loading')" />
        ...
      </iframe>
    </div>
  </v-container>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import { CustomFormatter } from '@/views/translations/CustomFormatter'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      entireProcessingFormObject: {},
      subsidyInvoiceId: 0,
      subsidyMessage: '',
      loading: false
    }
  },
  methods: {
    async getProcessingForm() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id,
          {
            id: this.$route.params.id
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },

    async calcInvoice() {
      this.subsidyInvoiceId = 0
      const params = new URLSearchParams()
      params.append('processingFormId', this.$route.params.id)
      this.loading = true
      try {
        const result = await this.axios.post(apiURL + '/subsidyInvoices/processing/process', params, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        if (await result) {
          this.subsidyInvoiceId = result.data.id
          return result.data
        }
      } catch (e) {
        if (e?.response?.data.title === 'DBM_ERROR_125') {
          this.subsidyMessage = this.$t(e?.response?.data.title, e?.response?.data.placeholders)
          emitter.emit('toastSuccess', {
            message: this.subsidyMessage
          })
        } else {
          showError(e)
        }
      } finally {
        this.loading = false
      }
    },

    async loadPDF() {
      if (!this.subsidyInvoiceId) return
      try {
        this.loading = true
        const result = await this.axios.get(apiURL + '/subsidyInvoices/' + this.subsidyInvoiceId, {
          headers: { Accept: 'application/pdf' },
          responseType: 'blob'
        })

        if (await result) {
          //TODO  fileCreator einsetzen dazu muss die url ein content-disposition und filename mitgeben ..
          const file = new Blob([result.data], { type: 'application/pdf' })
          //Open the URL on new Window
          //window.open(URL.createObjectURL(file));
          //Build a URL from the file
          this.$refs.pdfContainer.src = URL.createObjectURL(file)
        }
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loading = false
      }
    },
    cancel() {
      this.$router
        .push({
          name: 'processingForms_read'
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    goForward() {
      this.$router
        .push({
          name: 'processingForms_edit_form_step_three',
          params: { id: this.$route.params.id }
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    goBack() {
      this.$router
        .push({
          name: 'processingForms_edit_form_step_one',
          params: { id: this.$route.params.id }
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    generateCustomSubHeaderTextfield() {
      const mbhId = this.entireProcessingFormObject?.dairyIdent ? this.entireProcessingFormObject?.dairyIdent : ''
      const mbhName1 = this.entireProcessingFormObject?.dairyName1 ? this.entireProcessingFormObject?.dairyName1 : ''
      const mbhName2 = this.entireProcessingFormObject?.dairyName2 ? this.entireProcessingFormObject?.dairyName2 : ''
      const formatter = new CustomFormatter()
      return formatter.interpolate(this.$t('processing_form_edit_subheader_step_two'), [mbhId, mbhName1, mbhName2])
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      this.entireProcessingFormObject = await this.getProcessingForm()
      await this.calcInvoice()
      await this.loadPDF()
    })
  }
})
</script>

<style></style>
